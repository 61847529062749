<template>
    <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-large modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{
                            this.pointTypeModel.id
                                ? (this.modeView ? "" : $t("button.edit"))
                                : $t("button.add")
                        }} {{ $t("module.transactionPointType") }}</h5>
                </div>
                <form @submit.prevent="submitData()">
                    <div class="modal-body">
                        <input
                            type="hidden"
                            class="form-control"
                            v-model.number="pointTypeModel.id"
                        />
                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label"
                                >{{ $t("common.name") }}*:</label
                            >
                            <div class="col-lg-8">
                                <input type="text" :disabled="modeView"
                                    class="form-control" v-model="pointTypeModel.type_name"
                                    :placeholder="$t('common.name')"
                                    v-validate="{required:true,max:100 }"
                                    :data-vv-as="$t('common.name')"
                                    name="type_name" :class="{'is-invalid': errors.has('type_name')}"
                                />
                                <div v-show="errors.first('type_name')" class="invalid-feedback">
                                    {{ errors.first("type_name") }}
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label"
                                >{{ $t("common.description") }}:</label
                            >
                            <div class="col-lg-8">
                                <input type="text" :disabled="modeView" class="form-control" v-model="pointTypeModel.descriptions" :placeholder="$t('common.description')" v-validate="" :data-vv-as="$t('common.description')" name="descriptions" :class="{'is-invalid': errors.has('descriptions')}"
                                />
                                <div v-show="errors.first('descriptions')" class="invalid-feedback">
                                    {{ errors.first("descriptions") }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-clean btn-bold btn-upper btn-font-md"
                            @click="hideModal()"
                        >
                            {{ $t("button.close") }}
                        </button>
                        <button
                            v-if="!modeView"
                            type="submit"
                            class="btn btn-default btn-bold btn-upper btn-font-md"
                        >
                            {{ $t("button.save") }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import SweetAlertTemplate from "./../../../components/_general/SweetAlert";
import { RepositoryFactory } from "./../../repositories/BaseRepositoryFactory";
import { Validator } from "vee-validate";
import VeeValidate from "vee-validate";
import { isNullOrUndefined } from "util";
import TransactionPointModel from "./../../model/transaction-point-model";
import { mapState, mapMutations, mapActions } from "vuex";

import BlockUI from "./../../../components/_js/BlockUI";
const blockUI = new BlockUI();
const transactionPointTypeRepository = RepositoryFactory.get("transactionPointTypeRepository");
const SweetAlert = Vue.extend(SweetAlertTemplate);

VeeValidate.setMode("passive");
export default {
    props: {
        event: {
            type: Function
        }
    },
    data() {
        return {
            pointTypeModel: new TransactionPointModel()
        };
    },
    provide() {
        return { $validator: this.$validator };
    },
    computed: {
        ...mapState("Crud", {
            modalName: state => state.modalName,
            dataEdit: state => state.dataEdit,
            showModalMode: state => state.showModalMode
        }),
        modeView(){
            return this.showModalMode == 'V';
        }
    },
    methods: {
        ...mapActions({
            createNew: "Crud/createNew",
            updateById: "Crud/updateById"
        }),
        ...mapMutations({
            clearData: "Crud/CLEAR_DATA",
            refreshData: "Crud/REFRESH_TABLE",
            setEditData: "Crud/SET_EDIT_DATA",
            hideModal: "Crud/HIDE_MODAL",
        }),
        getServerError(error, defaultMsg) {
            if (_.isUndefined(error.response.data.params)) {
                return defaultMsg;
            }
            let serverError = error.response.data.params;
            let errorMsg = _.isUndefined(
                serverError[Object.keys(serverError)[0]][0]
            )
                ? defaultMsg
                : serverError[Object.keys(serverError)[0]][0];
            return errorMsg;
        },
        updateData: function() {
            if (this.dataEdit) {
                this.pointTypeModel = Object.assign({}, this.dataEdit);
            }
        },
        submitData: function() {
            this.$validator.validateAll().then(result => {
                if (result) {
                    var vx = this;
                    if (this.pointTypeModel.id) {
                        let instance = new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Perubahan data akan tersimpan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                const data = {};
                                _.forEach(vx.pointTypeModel, (v, k) => {
                                    if(v != null){
                                        data[k] = v;
                                    }
                                });
                                vx.update(data, vx.pointTypeModel.id);
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    } else {
                        let instance = new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Data akan ditambahkan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                const data = {};
                                _.forEach(vx.pointTypeModel, (v, k) => {
                                    if(v != null){
                                        data[k] = v;
                                    }
                                });
                                vx.create(data);
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    }
                    return;
                }
            });
        }, //submitdata
        async create(payload) {
            var vx = this;
            await transactionPointTypeRepository.create(payload)
                .then(response => {
                    new Promise((resolve, reject) => {
                            vx.$emit("event", resolve);
                            resolve(response);
                        }).
                        then((response) => {
                            let instance = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: "Data Tipe Poin Transaksi berhasil ditambahkan.",
                            swalType: "success",
                            onConfirmButton: function () {
                                vx.refreshData(true);
                                blockUI.unblockModal();
                                vx.hideModal();
                                }
                            });
                        }).catch(error=>{

                        });
                    })
                    .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: vx.getServerError(
                            error,
                            "Data Kategori gagal ditambahkan."
                        ),
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                    });
            },
            async update(payload,pointTypeId) {
                var vx = this;

                await transactionPointTypeRepository.update(payload,pointTypeId)
                    .then(response => {
                        new Promise((resolve, reject) => {
                            vx.$emit("event", resolve);
                            resolve(response);
                        }).then((response) => {
                            let instance = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: "Data Tipe Poin Transaksi berhasil diubah.",
                            swalType: "success",
                            onConfirmButton: function() {
                                vx.refreshData(true);
                                blockUI.unblockModal();
                            }
                        });
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: vx.getServerError(
                            error,
                            "Data Kategori gagal diubah."
                        ),
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                });
        }

    }, //methods
    mounted() {
        $("#" + this.modalName).modal();
        this.updateData();
    }
};
</script>
